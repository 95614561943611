import {
  type LoaderData,
  useLoaderData,
  useNavigation,
  type LoaderFunction,
  wordpressLoader,
  json,
  disk,
  redirect
} from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import type { GlobalCounter } from '~/graphql/types'

// Middleware
import middlewareHeader, { type MiddlewareHeader } from '~/middleware/header.server'
import middlewareVacancies, { type MiddlewareVacancies } from '~/middleware/vacancies.server'
import middlewareCandidates, { type MiddlewareCandidates } from '~/middleware/candidates.server'
import middlewareRelatedVacancies, { type MiddlewareRelatedVacancies } from '~/middleware/relatedVacancies.server'
import middlewareHighlightedVacancies, { type MiddlewareHighlightedVacancies } from '~/middleware/highlightedVacancies'
import { checkIfRedirectToVacancy } from '~/utils/old_vacancies'

export type PageBodyData = LoaderData & {
  middleware?: MiddlewareVacancies & MiddlewareCandidates & MiddlewareRelatedVacancies & MiddlewareHeader & MiddlewareHighlightedVacancies
  rest: { globalCounters: GlobalCounter }
}
export type PageLoaderData = { body: PageBodyData; headers: {} }

export const loader: LoaderFunction = async (data) => {
  const url = new URL(data.request.url)
  const path = url.pathname.replace(/\/$/, '')
  const cachePathOnly = path === '/vacatures' || path === '/beschikbare-professionals' || path === '/beoordelingen'
  const searchParams = new URLSearchParams(url.search)
  const oldFunctionGroups = searchParams.get('tex_functiongroups')

  switch (oldFunctionGroups) {
    case 'engineering':
      return redirect('/beschikbare-professionals/?Vakgebieden=Engineering', 301)

    case 'assemblage':
      return redirect('/beschikbare-professionals/?Vakgebieden=Assemblage', 301)

    case 'operators':
      return redirect('/beschikbare-professionals/?Vakgebieden=Operators', 301)

    case 'service-onderhoud':
      return redirect('/beschikbare-professionals/?Vakgebieden=Service+%26+Onderhoud', 301)
  }

  if (url.pathname.endsWith('/')) {
    const newUrl = checkIfRedirectToVacancy(`${url.pathname}`)
    if (newUrl) {
      return redirect(newUrl)
    }
  } else {
    const newUrl = checkIfRedirectToVacancy(`${url.pathname}/`)
    if (newUrl) {
      return redirect(newUrl)
    }
  }

  let page

  // Cache the path without the query params. Keeps the overview with filter params fast
  if (cachePathOnly) {
    page = (await disk.get(`pages/${url.pathname}`)) as unknown as PageLoaderData
  }

  if (!page) {
    page = (await wordpressLoader(data)) as unknown as PageLoaderData
    if (cachePathOnly) await disk.set(`pages/${url.pathname}`, page, 60 * 10)
  }

  await middleware(page, data.request)

  return json(page.body, page.headers)
}

export async function middleware(page: PageLoaderData, request: Request) {
  await Promise.all([
    middlewareHeader(page),
    middlewareVacancies(page, request),
    middlewareCandidates(page, request),
    middlewareRelatedVacancies(page, request),
    middlewareHighlightedVacancies(page, request)
  ])
}

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  const transition = useNavigation()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      progress.start()
    } else {
      progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex as any} />
    </Layout>
  )
}
